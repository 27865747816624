import React from "react"

import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import { Link } from "gatsby"
import { faGrinBeamSweat, faWrench } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const NotFoundPage = () => (
  <Layout>
    <SEO title="500 Internal Error" />
    <p style={{ fontSize: 100 }}>
      <FontAwesomeIcon icon={faGrinBeamSweat} />
      <FontAwesomeIcon icon={faWrench} />
    </p>
    <h1>SOMETHING WENT WRONG</h1>
    <p>
      Oops, some monkey did't do his job properly, please try again later{" "}
      <span aria-label=":wave:" role="img">
        😅
      </span>
    </p>

    <br />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default NotFoundPage
